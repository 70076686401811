import React from 'react';
import Modal from "./Modal";

export default  function EmptyLoadingSection ()  {
	// defaultně načítání zabere polovinu výšky displeje
	const windowHeight = (window.innerHeight || window.document.documentElement.clientHeight || window.document.body.clientHeight || 200) / 2;
	return <Modal.Section>
		<Modal.Content>
			<div style={{width: `${windowHeight}px`, height: `${windowHeight}px`}}/>
		</Modal.Content>
	</Modal.Section>
}