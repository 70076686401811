import { fetchGraphQL } from '../../utils/graphql.js';

export default {
	setCookieConsent: (consent) => fetchGraphQL(`
		mutation SetCookieConsent(
			$consent: String!,
		) {
		setCookieConsent(
			consent: $consent,
		)}`,
		{
			consent
		}
	),
}