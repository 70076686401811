import React from 'react';
import PropTypes from 'prop-types';
import i18n from "i18next";

export default class Loading extends React.Component {

	static defaultProps = {
		text: null,
		cssClassPrefix: 'table-data',
		small: false,
	};

	render()
	{
		const {text, cssClassPrefix, small} = this.props;

		const contentSuffix = small ? "-loading-inner" : "-loading-content";
		const animationClass = small ? (cssClassPrefix+"-loading-animation") : "svg-animation";


		return (
			<div className={cssClassPrefix + "-loading"}>
				<div className={cssClassPrefix + contentSuffix}>
					<div className={animationClass} />
					<h3>{text || i18n.t('text[webnode][__global_loading__]', 'Wait please...')}</h3>
				</div>
			</div>
		);
	}

}

Loading.propTypes = {
	text: PropTypes.string,
	cssClassPrefix: PropTypes.string,
	small: PropTypes.bool
};