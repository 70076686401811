import React from 'react';
import * as cookies from '../../utils/cookies';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../Pages/Checkout/actions';
import Keen from '../../utils/Tracker';
import queries from './queries.js';
import {isReleased} from '../../utils/utils';
import Strip from './variants/Strip.jsx';
import PropTypes from 'prop-types';
import Popup from './variants/Popup.jsx';

/**
 * Cookie bar
 */
class CookieBar extends React.Component 
{

	/**
	 * Jméno cookie do které se ukládá potvrzení cookies
	 * Musí být stejné jako v CookieDisclosureService::ALLOWED_COOKIES_COOKIE_NAME
	 */
	static ALLOWED_COOKIES_COOKIE_NAME = 'ac';

	/**
	 * Pocet milisekund zavírací animace
	 * @type {number}
	 */
	static ANIMATE_DURATION = 400;
	static ANIMATE_DURATION_POPUP = 200;

	static MINIMAL_TYPES = {
		essential: true,
		functional: false,
	};

	/**
	 * Initial state
	 */
	state = {
		open: false,
		forceOpen: false, // must be extra, because open handles only the animation
		viewManage: false,
		expandedManage: false,
		animationInProgress: false,
		animationCompleted: false,
		types: {...CookieBar.MINIMAL_TYPES},
		forceVariant: new URLSearchParams(this.props.location.search).get('forceCookieBarVariant'),
		popupCheckboxAnimation: false,
	};


	componentDidMount()
	{
		// we only log cookiebar_show event if the cookie bar is really shown
		if (!(cookies.get(CookieBar.ALLOWED_COOKIES_COOKIE_NAME) || this.props.userIsLoggedIn))
		{
			Keen.addKeenEvent('cookiebar_show', {
				data: {
					userHash: cookies.get('tu'),
					version: this.getDisplayedCookieBarVersion(),
				},
			});
		}

		globalThis.setTimeout(this.handleOpen, 100);

		// since footer is nor always render by react, so we need to display it based on external event
		globalThis.document.addEventListener('showCookieBar', this.handleReOpen);
		this.saveToUserDatabase();
	}

	componentWillUnmount()
	{
		globalThis.document.removeEventListener('showCookieBar', this.handleReOpen);
	}

	handleReOpen = () =>
	{
		let types = this.state.types;
		try
		{
			types = JSON.parse(cookies.get(CookieBar.ALLOWED_COOKIES_COOKIE_NAME));
		}
		catch 
		{ /* empty */ }

		//opens only after rerender for smooth animation
		this.setState(
			{
				forceOpen: true,
				open:false,
				types,
			},
			() => globalThis.setTimeout(this.handleOpen, 0),
		);
	};

	handleOpen = () => 
	{
		this.setState({
			open: true,
			viewManage: true,
		});
	};

	/**
	 * Zobrazení/Skrytí checkboxů
	 */
	handleToggleManage = () => 
	{
		if (this.state.expandedManage && this.props.enableManage)
		{
			this.handleAcceptSelected();
		}
		else
		{
			Keen.addKeenEvent('cookiebar_choices_click', {
				data: {
					userHash: cookies.get('tu'),
					version: this.getDisplayedCookieBarVersion(),
				},
			});

			this.setState({
				expandedManage: !this.state.expandedManage,
				animationInProgress: true,
			});

			// Nastavím časový interval, po kterém se opět zobrazí zlačítko "Manage my choices" / "Accept selected"
			setTimeout(function() 
			{
				this.setState({
					animationInProgress: false,
					animationCompleted: true,
				});
			}.bind(this), CookieBar.ANIMATE_DURATION);

			// interval for animating checkboxes in popup cookie variant
			setTimeout(function() 
			{
				this.setState({
					popupCheckboxAnimation: true,
				});
			}.bind(this), CookieBar.ANIMATE_DURATION_POPUP);
		}
	};

	/**
	 * Změna stavu checkboxů
	 * @param {string} type
	 */
	handleToggleCheckbox = (type) => 
	{
		this.setState(prevState => ({
			types: {
				...prevState.types,
				[type]: !prevState.types[type],
			},
		}));
	};

	/**
	 * Schválení všech
	 */
	handleAcceptAll = () => 
	{
		Keen.addKeenEvent('cookiebar_accept_all', {
			data: {
				userHash: cookies.get('tu'),
				version: this.getDisplayedCookieBarVersion(),
			},
		});

		// přenastavíme všechny checkboxy na true
		const types = {...this.state.types};
		Object.keys(types).map((key) => 
		{
			types[key] = true;
		});

		this.setState({
			open: false,
			types: types,
		});

		globalThis.setTimeout(this.saveToCookie, CookieBar.ANIMATE_DURATION);
	};

	handleClose = () => 
	{
		this.setState({types: CookieBar.MINIMAL_TYPES}, this.handleAcceptSelected);
	};

	/**
	 * Schválení vybraných (ve výchozím stavu jen neccecery)
	 */
	handleAcceptSelected = () => 
	{
		Keen.addKeenEvent('cookiebar_accept_chosen', {
			data: {
				userHash: cookies.get('tu'),
				cookieData: this.state.types,
				version: this.getDisplayedCookieBarVersion(),
			},
		});

		this.setState({
			open: false,
		});

		globalThis.setTimeout(this.saveToCookie, CookieBar.ANIMATE_DURATION);

	};

	/**
	 * Uložení hodnot do cookie
	 */
	saveToCookie = () => 
	{

		const types = {...this.state.types};

		// mělo by být marketing, ale je to ted hozeno pod functional :/


		const consentState = {
			'ad_storage': 'denied',
			'analytics_storage': 'denied',
			'ad_user_data': 'denied',
			'ad_personalization': 'denied',
		};
		if (types.functional)
		{
			consentState.ad_storage = 'granted';
			consentState.analytics_storage = 'granted';
			consentState.ad_user_data = 'granted';
			consentState.ad_personalization = 'granted';
		}

		globalThis.gtag('consent', 'update', consentState);
		dataLayer.push({
			event: 'consent_update',
			consent: consentState,
			_clear: true,
		});

		// musíme to ukládat do cookie, aby to šlo přečíst i z PHP
		cookies.set(CookieBar.ALLOWED_COOKIES_COOKIE_NAME, JSON.stringify(types), 365); // 1 Year
	};

	saveToUserDatabase = () =>
	{
		if (this.props.userIsLoggedIn)
		{
			void queries.setCookieConsent(
				cookies.get(CookieBar.ALLOWED_COOKIES_COOKIE_NAME) || JSON.stringify(CookieBar.MINIMAL_TYPES),
			);
		}
	};

	/**
	 * Zjistí, která varianta cookie baru se reálně zobrazí uživateli
	 */
	getDisplayedCookieBarVersion = () =>
	{
		if (isReleased('newCookieBarWide') || this.state.forceVariant === 'wide')
		{
			return 'cookie_modal_2024_buttons_horizontal';
		}
		else if (isReleased('newCookieBarNarrow') || this.state.forceVariant === 'narrow')
		{
			return 'cookie_modal_2024_buttons_vertical';
		}

		return 'cookie_stripe_2021';
	};



	/**
	 * Vykreslí cookie bar
	 *
	 * @returns {JSX.Element}
	 */
	render()
	{

		if (!this.state.forceOpen && (cookies.get(CookieBar.ALLOWED_COOKIES_COOKIE_NAME) || this.props.userIsLoggedIn))
		{
			return null; // pokud je cookie bar už odeslaný nebo je uživatel přihlašený, tak ho nezobrazujem
		}

		const cookieBarProps = {
			open: this.state.open,
			cookieDisclosureUrl: this.props.cookieDisclosureUrl,
			expandedManage: this.state.expandedManage,
			animationCompleted: this.state.animationCompleted,
			types: this.state.types,
			handleToggleManage: this.handleToggleManage,
			handleAcceptAll: this.handleAcceptAll,
			handleToggleCheckbox: this.handleToggleCheckbox,
			handleAcceptSelected: this.handleAcceptSelected,
			handleClose: this.handleClose,
		};

		/*
			choice of cookie bar variants to render:
			<Popup> - modal; "variant" prop says if the variant is "wide" or "narrow"
			<Strip> - stripe at the botto of the page

		 */
		if (isReleased('newCookieBarWide') || this.state.forceVariant === 'wide')
		{
			return <Popup
				{...cookieBarProps}
				popupCheckboxAnimation={this.state.popupCheckboxAnimation}
				variant='wide'
			/>;
		}
		else if (isReleased('newCookieBarNarrow') || this.state.forceVariant === 'narrow')
		{
			return <Popup
				{...cookieBarProps}
				popupCheckboxAnimation={this.state.popupCheckboxAnimation}
				variant='narrow'
			/>;
		}

		return <Strip
			{...cookieBarProps}
			viewManage={this.state.viewManage}
			enableManage={this.props.enableManage}
			animationInProgress={this.state.animationInProgress}
			moreImportance={this.props.moreImportance}
		/>;
	}
}


CookieBar.propTypes = {
	location: PropTypes.object,
	enableManage: PropTypes.bool,
	userIsLoggedIn: PropTypes.bool,
	cookieDisclosureUrl: PropTypes.string,
	moreImportance: PropTypes.bool.isRequired,

};


export default connect(
	(state, ownProps) => ({userIsLoggedIn: state.layout.userIsLoggedIn, ...ownProps}),
	(dispatch) => bindActionCreators(actions, dispatch),
)(CookieBar);
