import * as actions from './actions';

export const initialState = {
	inputs: {
		email: {
			value: '',
			error: null,
			valid: false,
		},
		password: {
			value: '',
			error: null,
			valid: false,
		},
	},
	data: {},
	trackingData: [],
};


export default function (state = initialState, action)
{
	switch (action.type)
	{
		case actions.LOGIN_SET_ERROR:
			return {
				...state,
				inputs: {
					...state.inputs,
					[action.name]: {
						...state.inputs[action.name],
						error: action.error,
						valid: action.error === null,
					},
				}
			};
		case actions.LOGIN_SET_DATA:
			return {
				...state,
				data: action.data
			};
		case actions.LOGIN_CHANGE_INPUT:
			return {
				...state,
				inputs: {
					...state.inputs,
					[action.name]: {
						// při změně value se vyresetuej error
						value: action.value,
						error: null,
					},
				},
			};
		case actions.LOGIN_TRACK_INPUT:
			return {
				...state,
				trackingData: [
					...state.trackingData,
					action.trackingEvent
				]
			}
		default:
			return state;
	}
}