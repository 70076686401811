import './utils/polyfill/polyfills'; // musí být první
import React from 'react';
import i18n from 'i18next';
import configureStore from './utils/store';
import {combineReducers} from 'redux';
import { createRoot } from 'react-dom/client';
import {Provider} from 'react-redux';
import {getStaticData, isReleased} from './utils/utils';
import CookieBar from './Modules/CookieBar/CookieBar';

import registerForm from './Modules/Register/reducer';
import loginForm from './Modules/Login/reducer';
import layoutReducer from './Pages/Layout/reducer';

// registratátory eventů pro ssr stranky
import {
	registerAIMigrationToolEvents  as registerAIMigrationToolRegisterEvents,
	registerEvents as registerRegisterEvents,
	registerAIWebsiteBuilderEvents as registerAIWebsiteBuilderRegisterEvents,
} from './Modules/Register/module';
import {registerEvents as registerLoginEvents} from './Modules/Login/module';
import * as actions from './Modules/RegisterLoginModal/actions';
import {initPage} from './Pages/Layout/actions';
import SSRLayoutModals from './Modules/SSRLayoutModals/SSRLayoutModals';

import loginRefresher from './utils/loginRefresher';
import {productionLog} from './utils/utils';
import ReCaptchaApi from './components/Ui/ReCaptcha/RecaptchaApi';

import Keen from './utils/Tracker.js';

console.log('%cWelcome to Webnode\n%c📌Create your own website for free! ✅', 'font-size: 2em', 'font-size: 1em');

productionLog('production Login Active');

i18n.init({
	...window['textsConfig'],
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false, // TODO mozna jen tam kde to bude fakt nutny
		prefix: '{',
		suffix: '}',
	},
});

// vytvorime reducer
const rootReducer = combineReducers({
	layout: layoutReducer,
	registerForm,
	loginForm,
});


const staticData = getStaticData();
if ('reCaptcha' in staticData)
{
	ReCaptchaApi.init(staticData.reCaptcha);
}

// vytvorime store
const store = configureStore(rootReducer);

store.dispatch(initPage(staticData));

// navesime eventy na selectory pro otevirani registracniho a login okna
registerRegisterEvents((data) => store.dispatch(actions.openRegisterModal(data)));
registerLoginEvents((data) => store.dispatch(actions.openLoginModal(data)));

// Only when form of AI migration tool is available on the page
if (staticData?.AIMigrationFormAvailable === true)
{
	registerAIMigrationToolRegisterEvents(
		(data, sourceButton) => store.dispatch(actions.startAIMigrationRegistration(data, sourceButton)),
	);
}
if (staticData?.AIWebsiteBuilderFormAvailable === true)
{
	Keen.addKeenEvent('aiwizard_lp_loaded', {
		'aiwizardVersion': isReleased('AIWizard2') ? 2 : 1,
	});

	registerAIWebsiteBuilderRegisterEvents(
		(data) => store.dispatch(actions.openRegisterModal(data)),
	);
}


const modalRoot = createRoot(window.document.getElementById('modal-root'));
// render modals
modalRoot.render(
	<Provider store={store}>
		<SSRLayoutModals staticData={staticData} />
	</Provider>,

);

// render cookie bar
if (isReleased('portalCookieBar'))
{

	const cookieBarRoot = createRoot(window.document.getElementById('cbar-root'));
	cookieBarRoot.render(
		<Provider store={store}>
			<CookieBar
				moreImportance={isReleased('portalCookieBarDACH')}
				enableManage={isReleased('portalCookieBarManage')}
				cookieDisclosureUrl={staticData.cookieDisclosureUrl}
				location={window.location}
			/>
		</Provider>
	);
}
// render some other component

// po vykreslení začneme timer
loginRefresher.start();
