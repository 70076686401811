import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from 'i18next';
import Loading from '../../components/DataTable/Loading';
import ActionSpanLink from '../Ui/ActionSpanLink';
import EmptyLoadingSection from './EmptyLoadingSection';
import {singleExec} from '../../utils/executionLimiters';

// Create a theme context, defaulting to light theme
const WrapperContext = React.createContext();

const MODAL_SPACING_CONTENT_SCROLL = 40;
const MODAL_SPACING_WRAPPER_SCROLL = 20;


/**
 * Modalni okno
 */
export default class Modal extends React.Component {

	static defaultProps = {
		loading: false,
		showCloseButton: false,
		forceOnTop: false,
		forceOverlay: false,
		closeOnOverlay: true,
	};

	static inheritTypes = {
		section: PropTypes.string,
		loading: PropTypes.bool,
	};

	/**
     * Inicializace
     */
	constructor(props)
	{
		super(props);

		this.contentEl = null;

		this.heights = {headerHeight: 0, footerHeight: 0};

		this.overleyExists = !this.props.forceOverlay && !!window.document.querySelector('.modal-overlay');

		this.state = {
			scrollWrapper: false,
			contentStyle: {},
			wrapperStyle: {},
		};

		this.updateDimensions = this.updateDimensions.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}

	/**
     * Před vytvoření komponenty
     */
	componentDidMount()
	{
		window.document.body.classList.add('active-table-data');
		window.addEventListener('resize', this.updateDimensions);
		window.document.addEventListener('keydown', this.handleKeyDown);

		this.updateDimensions();
	}

	/**
     * Před ztušením komponenty
     */
	componentWillUnmount()
	{
		window.document.body.classList.remove('active-table-data');
		window.removeEventListener('resize', this.updateDimensions);
		window.document.removeEventListener('keydown', this.handleKeyDown);
	}


	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	componentDidUpdate(prevProps, prevState, snapshot)
	{
		// pokud se změnil pomery obsahu, přepočítáme dimenze
		if (this.heightsDiffer())
		{
			singleExec('modalHeight', () => this.updateDimensions());
		}
	}

	/**
     * Zpracování udalosti pri kliknutí na klávesnici
     * @param event
     */
	handleKeyDown(event)
	{
		if (event.type !== 'keydown')
		{
			return;
		}

		// prechod na predchozi | nasledujici sipkami na klavesnici | zavření
		switch(event.keyCode)
		{
			case 27: // esc
				this.props.onEsc ? this.props.onEsc(event) : this.props.onClose();
				break;
			case 37: // left
			case 38: // up
				this.props.onPrev ? this.props.onPrev(event) : null;
				break;
			case 39: // right
			case 40: // down
				this.props.onNext ? this.props.onNext(event) : null;
				break;

			default:
				this.props.onKeyDown ? this.props.onKeyDown(e) : null;
				break;
		}
	}

	computeHeights() {
		// TODO mozna prasarna, ale pres refs se mi to nepodarilo udelat
		const headerEl = this.contentEl?.querySelector('.table-data-modal-header');
		const headerHeight = headerEl ? headerEl.clientHeight : 0;
		const footerEl = this.contentEl?.querySelector('.table-data-modal-toolbar');
		const footerHeight = footerEl ? footerEl.clientHeight : 0;

		return {headerHeight, footerHeight};
	}

	updateHeights()
	{
		this.heights = this.computeHeights();
	}

	heightsDiffer()
	{
		const {headerHeight, footerHeight} = this.computeHeights();
		return headerHeight !== this.heights.headerHeight || footerHeight !== this.heights.footerHeight;
	}


	updateDimensions()
	{
		const recalculate = () => {
			this.updateHeights();
			const {headerHeight, footerHeight} = {...this.heights};

			// @see https://www.w3schools.com/Jsref/prop_win_innerheight.asp
			const windowHeight = window.innerHeight || window.document.documentElement.clientHeight || window.document.body.clientHeight;

			const modalMultiplier =
                windowHeight < 600 ? 0.95 : (windowHeight < 900 ? 0.9 : 0.8); // max-height is 90% or 80%
			const limitContentHeight = (windowHeight * modalMultiplier).toFixed() - ( 2 * MODAL_SPACING_CONTENT_SCROLL ) - headerHeight - footerHeight;

			let scrollWrapper;
			let contentStyle = {};
			let wrapperStyle = {};

			if (limitContentHeight <= 150)
			{
				scrollWrapper = true;
				wrapperStyle = {
					height: windowHeight - (2 * MODAL_SPACING_WRAPPER_SCROLL),
				};
			}
			else
			{
				scrollWrapper = false;
				contentStyle = {
					maxHeight: limitContentHeight,
					overflowX: 'hidden',
					overflowY: 'auto',
				};
			}
			return {scrollWrapper, wrapperStyle, contentStyle};
		};

		this.setState(
			recalculate(),
			// after state change
			() =>
			// on the end of stack
				globalThis.setTimeout(
					() =>
					// wait one frame
						globalThis.requestAnimationFrame(
							// recalculate again
							() => this.setState(recalculate()),
						)
					,
					100
					,
				),
		);
	}


	render()
	{
		const {scrollWrapper, wrapperStyle} = this.state;
		const {loading, onClose, closeOnOverlay} = this.props;

		const childContext = {
			scrollWrapper: this.state.scrollWrapper,
			contentStyle: this.state.contentStyle,
		};

		// TODO background none v overlay je jen kvuli tomu že SignUp modal je uz v html vykresleny v layutu,
		// a pak to zobrazuje dva overlay na sobě :(
		// existovat tu ale musí protože je na něm onClick
		// TODO oddělat až bude i SignUp modal v Reactu

		return (
			<div className="modal" ref={(el) => this.contentEl = el} style={this.props.forceOnTop ? {zIndex: 999} : {}}>
				<div className="modal-content">
					<div className="modal-overlay" style={this.overleyExists ? {background: 'none'} : {}} onClick={closeOnOverlay ? onClose : () => undefined}
					/>
					<div className={classNames(loading && 'waiting', 'table-data-modal modal-box modal-content-allow-scroll open', scrollWrapper && 'modal-wrapper-allow-scroll')}>
						<div className={classNames('table-data-modal-wrapper', scrollWrapper && 'scroll-shadows')} style={wrapperStyle}>
							<div className="table-data-modal-content modal-box-content">
								{loading &&
								<Loading
									cssClassPrefix="table-data-modal"
									text={i18n.t('text[webnode][__global_loading__]', 'Wait please...')}
								/>
								}

								<WrapperContext.Provider value={childContext}>
									{this.props.children}
									{
										React.Children.count(this.props.children) === 0 && loading &&
										<EmptyLoadingSection/>
									}
								</WrapperContext.Provider>

							</div>
						</div>
						{this.props.topControls && <div className="modal-top-controls">{this.props.topControls}</div>}
						{this.props.showCloseButton &&
							<ActionSpanLink key="close" className="modal-close" onClick={onClose}>
								{i18n.t('text[footer][morePagesClose]', 'Close')}
							</ActionSpanLink>
						}
					</div>
				</div>
			</div>
		);
	}
}

Modal.propTypes = {
	loading: PropTypes.bool,
	showCloseButton: PropTypes.bool,
	onClose: PropTypes.func,
	onEsc: PropTypes.func,
	onPrev: PropTypes.func,
	onNext: PropTypes.func,
	onKeyDown: PropTypes.func,
	topControls: PropTypes.node,
	forceOnTop: PropTypes.bool,
	forceOverlay: PropTypes.bool,
	children: PropTypes.node,
	closeOnOverlay: PropTypes.bool,
};


Modal.Header = ({className = '', children}) => (
	<div className={classNames('table-data-modal-header', className)}>
		{children}
	</div>
);

Modal.Header.displayName = 'ModalHeader';

Modal.Header.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

Modal.Content = ({className = '', style={}, children}) => (
	<WrapperContext.Consumer>
		{context =>
			<div
				className={classNames('table-data-modal-content', !context.scrollWrapper && 'scroll-shadows', className)}
				style={{
					...style,
					...context.contentStyle,
				}}
			>
				{children}
			</div>}
	</WrapperContext.Consumer>
);

Modal.Content.displayName = 'ModalContent';

Modal.Content.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	children: PropTypes.node,
};

function ModalContentArticle({className = '', style={}, children}) {
	return (
		<div className={classNames('table-data-modal-content-article', className)} style={style}>
			{children}
		</div>
	);
}

ModalContentArticle.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	children: PropTypes.node,
};

Modal.Content.Article = ModalContentArticle;

Modal.Content.Article.displayName = 'ModalContentArticle';

Modal.Footer = ({className = '', children}) => (
	<div className={classNames('table-data-modal-toolbar', className)}>{children}</div>
);

Modal.Footer.displayName = 'ModalFooter';

Modal.Footer.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

Modal.Section = ({className = '', children}) => (
	<div className={classNames('modal-section', className)}>{children}</div>
);

Modal.Section.displayName = 'ModalSection';

Modal.Section.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

function ModalFooterButton({className = '', onClick, href = '', children, disabled}) {
	return (
		<div className={classNames(className, {'state-disabled': disabled})} onClick={onClick}>
			<a onClick={e => e.preventDefault()} href={href}>
				<span>{children}</span>
			</a>
		</div>
	);
}

ModalFooterButton.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	children: PropTypes.node,
	disabled: PropTypes.bool,
};

Modal.Footer.Button = ModalFooterButton;
Modal.Footer.Button.displayName = 'ModalFooterButton';
