/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 12. 10. 2020 12:06
 */
import Keen from '../../utils/Tracker.js';
import {isReleased} from '../../utils/utils.js';

const requestData = {
	clickSource: '',
	wizardModule: null,
	wizardTemplate: null,
	wizardCategory: null,
	source: 'modal',
};

export function registerEvents(onRegisterClicked)
{
	window['setPreviewData'] = (data) => 
	{
		requestData.wizardModule = data.wizardModule;
		requestData.wizardTemplate = data.hash;
	};

	const dataEl = document.querySelector('.select-template');
	if (dataEl)
	{
		requestData.clickSource = dataEl.getAttribute('data-ref');
		requestData.wizardModule = dataEl.getAttribute('data-module');
		requestData.wizardTemplate = dataEl.getAttribute('data-template');

		document.querySelector('.live-preview-use').addEventListener('click', (e) => 
		{
			e.preventDefault();
			e.stopPropagation();
			onRegisterClicked(requestData);
		});
	}

	for ( const el of document.querySelectorAll('a[class~="signup-submit"]') )
	{
		el.addEventListener('click', (e) => 
		{
			e.preventDefault();
			e.stopPropagation();

			// TODO prenest i do akci kde se to pouziva

			const buttonData = {};
			const wizardCategory = e.target.getAttribute('data-wizard_category');
			if (wizardCategory && wizardCategory !== 'undefined')
			{
				buttonData.wizardCategory = wizardCategory;
			}
			const wizardTemplate = e.target.getAttribute('data-template');
			if (wizardTemplate && wizardTemplate !== 'undefined')
			{
				buttonData.wizardTemplate = wizardTemplate;
			}
			const ref = e.target.getAttribute('data-ref');
			if (ref && ref !== 'undefined')
			{
				buttonData.clickSource = ref;
			}
			onRegisterClicked({...requestData, ...buttonData});
		});
	}
}

export function registerAIMigrationToolEvents(onRegisterClicked) 
{

	const scraperButtons = document.querySelectorAll('.ai-scraper-submit');
	scraperButtons.forEach(scraperButton =>
	{
		scraperButton.addEventListener('click', (e) => 
		{
			e.preventDefault();
			e.stopPropagation();

			const inputValue = scraperButton.closest('.lp-ai-input-block').querySelector('.ai-scraper-url-input').value;

			const data = {
				scrapedURL: inputValue,
				clickSource: 'ai-migration-tool',
			};

			onRegisterClicked(data, scraperButton);
		});
	});
}

export function registerAIWebsiteBuilderEvents(onRegisterClicked) 
{
	const scraperButtons = document.querySelectorAll('.ai-website-builder-submit');
	scraperButtons.forEach(scraperButton =>
	{
		scraperButton.addEventListener('click', (e) => 
		{
			e.preventDefault();
			e.stopPropagation();

			const inputValue = document.querySelector('#ai-website-builder-input').value;

			if(inputValue)
			{

				localStorage.setItem('AIWizardWebsiteDescription', inputValue);
				localStorage.setItem('AIWizardSolidOverlay', true);

				const data = {
					websiteDescription: inputValue,
					clickSource: 'ai-website-builder-lp',
				};

				Keen.addKeenEvent('aiwizard_lp_button_clicked', {
					'aiwizardVersion': isReleased('AIWizard2') ? 2 : 1,
				});

				onRegisterClicked(data, scraperButton);
			}
			else
			{
				const inputBlock = document.querySelector('.lp-ai-input-block');
				inputBlock.classList.add('error');

				document.querySelector('.ai-website-builder-url-input').addEventListener('input', ()=>
				{
					inputBlock.classList.remove('error');
				}, { once: true });
			}
		});
	});
}