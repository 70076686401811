/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 17. 2. 2020 14:03
 */
import api from "./api";

const APP_REFRESH_TIME = 300000; // casovy limit pro spojeni na portál (5 min)


class LoginRefresher {

	constructor()
	{
		this.appId = -1;
	}


	start()
	{
		this.appId = setInterval(()=>this.__appTick(), APP_REFRESH_TIME);
	}


	stop()
	{
		clearInterval(this.appId);
	}


	renew()
	{
		this.stop();
		this.start();
	}

	__appTick()
	{
		api.loginRefresher.appRefresh();
	}
}

export default (new LoginRefresher());


