import { logger } from 'redux-logger';
import type { Tuple } from '@reduxjs/toolkit';
import {
	configureStore as configureStoreToolkit,
	type Middleware,
	type UnknownAction,
} from '@reduxjs/toolkit';
import {
	type Action,
	type Reducer,
	type ReducersMapObject,
} from 'redux';
import type { ThunkMiddlewareFor } from '@reduxjs/toolkit/dist/getDefaultMiddleware';


const isDev = process.env.NODE_ENV === 'development';

export default function configureStore<
	S = unknown,
	A extends Action = UnknownAction,
>(
	reducer: Reducer<S, A> | ReducersMapObject<S, A>,
	preloadedState?: S,
)
{
	return configureStoreToolkit<
		S,
		A,
		Tuple<[ThunkMiddlewareFor<S>]> | Tuple<[ThunkMiddlewareFor<S>, Middleware<S>]>
	>({
		reducer,
		middleware: (getDefaultMiddleware) => isDev ?
			getDefaultMiddleware({ immutableCheck: false }).concat(logger) :
			getDefaultMiddleware(),
		devTools: isDev,
		preloadedState,
	});
}
