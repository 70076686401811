/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 12. 10. 2020 12:06
 */

export function registerEvents(onLoginClicked)
{
	for ( let el of document.querySelectorAll('a[class~="login-submit"]') )
	{
		el.addEventListener('click', (e) => {
			e.preventDefault();
			e.stopPropagation();
			let clickSource = '';

			// TODO prenest i do akci kde se to pouziva

			const ref = e.target.getAttribute('data-ref');
			if (ref && ref !== 'undefined')
			{
				clickSource = ref;
			}
			onLoginClicked({
				source: 'modal',
				clickSource
			});
		});
	}
}