/**
 * Provede action s callbackem jen jednou za 100ms
 * tedy pokud by se zavolal vícekrát po sobě tak doběhne jen první prvního kdo volal
 * @param {string} action (klíč akce)
 * @param {function} callback
 * @param {?number} globalTimeout (přepíše timeout pro danou akci)
 */
export function singleExec(action, callback, globalTimeout = undefined)
{
    // doplníme data a přepíšeme global, pokud je zadán, nebo neeistuje
    const timeout = globalTimeout ?? singleExec.actions[action]?.timeout ?? 100;
    if (!singleExec.actions[action])
    {
        singleExec.actions[action] = {lastExecution: 0}
    }
    singleExec.actions[action].timout = timeout;

    // provede, pokud poslední provedení + timeout vypršelo
    const now = Date.now();
    if ((singleExec.actions[action].lastExecution + timeout) < now)
    {
        singleExec.actions[action].lastExecution = now;
        callback();
    }
}
singleExec.actions = {
    focus: {lastExecution: 0, timout: 100}, // jednou za 100ms
    modalHeight: {lastExecution: 0, timout: 50}
}


/**
 * Oproti singleExec se provolá až poslední colání potom co vyprší timeout (tipicky usecase jsou onchange ajax cally)
 * @param func
 * @param wait
 * @returns {(function(): void)|*}
 */
export function debounce(func, wait) {
    let timeout;
    return (...args) => {
        const later = () => {
            timeout = null;
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
