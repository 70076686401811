import React from 'react';
import PropTypes from 'prop-types';
import Trans from "../../../components/Ui/Trans.jsx";
import Collapsible from "../../../components/Ui/Accordion/Collapsible.jsx";
import ActionLink from "../../../components/Ui/ActionLink.jsx";
import CookieBar from "../CookieBar.jsx";
import exact from "prop-types-exact";

export default function Strip({
                                  open,
                                  cookieDisclosureUrl,
                                  viewManage,
                                  expandedManage,
                                  enableManage,
                                  animationCompleted,
                                  types,
                                  animationInProgress,
                                  moreImportance,
                                  handleToggleManage,
                                  handleAcceptAll,
                                  handleToggleCheckbox,
                                  handleAcceptSelected,
                                  handleClose
                              })
{

    return (
        <Collapsible opened={open} duration={CookieBar.ANIMATE_DURATION}>
            <div className="w-cookie-bar">
                <div className="w-cookie-bar-holder">
                    <div className="w-cookie-bar-content">
                        <h3>
                            <Trans i18nKey="text[portal-2015][cookiebar][header]"
                                   defaults="This website uses cookies"/>
                        </h3>
                        <p>
                            <Trans
                                i18nKey="text[portal-2015][cookiebar][content]"
                                defaults="We use cookies to enable the proper functioning and security of our websites,
										and help us offer you the best possible user experience. By clicking Accept,
										you consent to the use of these cookies for advertising and analytics.
										You can change your cookie settings at any time. For more information, please
										read more about the <link1>cookies we use</link1>."
                                components={{link1: <a href={cookieDisclosureUrl} target="_blank"/>}}
                            />
                        </p>

                        {viewManage && <div
                            className={"w-cookie-bar-content-action " + (expandedManage ? 'state-expanded' : 'state-collapsed')}>

                            {!enableManage &&
                                <Collapsible opened={!expandedManage} duration={CookieBar.ANIMATE_DURATION}>
                                    <p className={"w-cookie-bar-content-action-trigger"}>
                                        <ActionLink onClick={handleToggleManage}>
                                            <Trans
                                                i18nKey="text[portal-2015][cookiebar][manageMyChoices]"
                                                defaults="Manage my choices"
                                            />
                                        </ActionLink>
                                    </p>
                                </Collapsible>
                            }

                            <Collapsible opened={expandedManage} duration={CookieBar.ANIMATE_DURATION}>
                                <div
                                    className="w-cookie-bar-content-action-submenu"
                                    style={animationCompleted ? {
                                        transition: 'opacity 1s',
                                        opacity: 1
                                    } : {
                                        opacity: 0
                                    }}
                                >
                                    <form className="form" action="#" method="post">
                                        <div className="w-checkbox size-small">
                                            <label>
                                                <input
                                                    className="state-disabled"
                                                    type="checkbox"
                                                    name="essential"
                                                    id="essential"
                                                    checked={types.essential}
                                                    onChange={() => {
                                                    }}
                                                    disabled=""
                                                />
                                                <span className="w-checkbox-outer"/>
                                                <span className="w-checkbox-label-text">
													<Trans i18nKey="text[portal-2015][cookiebar][essential]"
                                                           defaults="Essential"/>
												</span>
                                            </label>
                                        </div>
                                        <div className="w-checkbox size-small">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="functional"
                                                    id="functional"
                                                    checked={types.functional}
                                                    onChange={() => handleToggleCheckbox('functional')}
                                                />
                                                <span className="w-checkbox-outer"/>
                                                <span className="w-checkbox-label-text">
													{/* Špatný identifikátor. Ve skutečnosti se jedná o marketing */}
                                                    <Trans i18nKey="text[portal-2015][cookiebar][performance]"
                                                           defaults="Marketing/third party"/>
												</span>
                                            </label>
                                        </div>
                                    </form>
                                </div>
                            </Collapsible>
                        </div>
                        }

                    </div>
                    <div className="w-cookie-bar-footer">
                        {enableManage ?
                            <ActionLink
                                className="w-button type-outline role-dark"
                                onClick={handleToggleManage}
                            >
									<span
                                        className="w-cookie-bar-manage-my-choices-action-trigger"
                                        style={animationInProgress ? {
                                            transition: '300 opacity',
                                            opacity: 0
                                        } : {}}
                                    >
										{expandedManage
                                            ? <Trans i18nKey="text[portal-2015][cookiebar][acceptSelected]"
                                                     defaults="Accept selected"/>
                                            : <Trans i18nKey="text[portal-2015][cookiebar][manageMyChoices]"
                                                     defaults="Manage my choices"/>
                                        }
									</span>
                            </ActionLink>
                            :
                            <ActionLink
                                className={moreImportance ? "w-button type-action" : "w-button type-text role-dark role-underline"}
                                onClick={handleAcceptSelected}
                            >
									<span
                                        className="w-cookie-bar-w-button-selected-cookies w-cookie-bar-w-button-accept-cookies"
                                        style={animationInProgress ? {
                                            transition: '300 opacity',
                                            opacity: 0
                                        } : {}}
                                    >
										{expandedManage
                                            ? <Trans i18nKey="text[portal-2015][cookiebar][acceptSelected]"
                                                     defaults="Accept selected"/>
                                            : <Trans i18nKey="text[portal-2015][cookiebar][acceptNecessary]"
                                                     defaults="Accept necessary"/>
                                        }
									</span>
                            </ActionLink>
                        }

                        <ActionLink className="w-button type-action">
								<span className="w-cookie-bar-w-button-accept-cookies" onClick={handleAcceptAll}>
									<Trans i18nKey="text[portal-2015][cookiebar][acceptAll]" defaults="Accept all"/>
                                </span>
                        </ActionLink>

                    </div>
                </div>
                <ActionLink
                    className="w-cookie-bar-close"
                    onClick={handleClose}
                >
						<span>
							<Trans i18nKey="text[portal-2015][cookiebar][acceptNecessary]" defaults="Accept necessary"/>
						</span>
                </ActionLink>
            </div>
        </Collapsible>
    );
}

Strip.propTypes = exact({
    open: PropTypes.bool.isRequired,
    cookieDisclosureUrl: PropTypes.string.isRequired,
    viewManage: PropTypes.bool.isRequired,
    expandedManage: PropTypes.bool.isRequired,
    enableManage: PropTypes.bool.isRequired,
    animationCompleted: PropTypes.bool.isRequired,
    types: PropTypes.objectOf(PropTypes.bool.isRequired).isRequired,
    animationInProgress: PropTypes.bool.isRequired,
    moreImportance: PropTypes.bool.isRequired,
    handleToggleManage: PropTypes.func.isRequired,
    handleAcceptAll: PropTypes.func.isRequired,
    handleToggleCheckbox: PropTypes.func.isRequired,
    handleAcceptSelected: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired
});
