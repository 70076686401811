import React from "react";
import RegisterLoginModal from "../RegisterLoginModal/RegisterLoginModal";
import * as actions from '../RegisterLoginModal/actions';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux'

/**
 * Zajištuje vykreslení modalů pro PHP layout
 */
class SSRLayoutModals extends React.Component
{


	// nic nevykreslujeme
	render() {

		return this.props.modal.section && this.props.modal.section.startsWith('RegisterLoginModal/') &&
			<RegisterLoginModal
				termsAndConsUrl={this.props.termsAndConsUrl}
				privacyUrl={this.props.privacyUrl}
				maxProjectNameLength={this.props.maxProjectNameLength}
				forgotPasswordUrl={this.props.forgotPasswordUrl}
				socialUrl={this.props.socialUrl}
				loginUrl={this.props.loginUrl}
				registerUrl={this.props.registerUrl}
				userIsLoggedIn={this.props.userIsLoggedIn}

				jsAntiSpamData={this.props.staticData.jsAntiSpamData}
				captchaPublicKey={this.props.staticData.captchaPublicKey}
				upgradeNotificationUrl={this.props.staticData.upgradeNotificationUrl}

				{...this.props.modal}
			/>
			;
	}
}

export default connect(
	(state, ownProps) => ({...state.layout, ...ownProps}),
	(dispatch) => bindActionCreators(actions, dispatch),
)(SSRLayoutModals)
