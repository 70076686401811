export const checkoutOrderFieldsFragment = /* GraphQL */`
    fragment OrderItemCommonFields on WebnodeOrderItemTypeInterface {
        type
        title
        hash
        variantsHeader
        variants {
            title
            price
            period
            variantHeader
            active
            interval {
                units
                period
            }
            recommendationLevel
            savings
        }
        period
        currentVariant {
            free
            price
            basePrice
            priceExplained
            firstYearFree
        }
        disabled
        checked
    }

    fragment OrderItemPackageAddonFields on WebnodeOrderPackageAddonItemType {
        info
        kbArticleUrl
        showMoreText
        label
        identifier
    }

    fragment OrderItemPackageFields on WebnodeOrderPackageItemType {
        packageIdentifier
        priceLevelDiscount
        levelDiscountedAmount
    }

    fragment OrderItemDomainInterfaceFields on WebnodeOrderDomainItemType {
        domain
        supportsFreeVoucher
    }

    fragment OrderItemPrivateRegistrationFields on WebnodeOrderPrivateRegistrationItemType {
        domain
        supportsFreeVoucher
        contact {
            address
            city
            company
            country {
                code
            }
            dic
            email
            firstname
            ico
            phone
            state
            surname
            zip
        }
        renew
        contactRequired
        missingAnyPersonalData
        privateChangeDisabled
        editPersonalInfoUrl
    }

    fragment OrderItemPluginFields on WebnodeOrderPluginItemType {
        info
        kbArticleUrl
        showMoreText
        label
        identifier
    }

    fragment CheckoutOrderFields on WebnodeOrder {
        identifier
        price {
            currency {
                code
            }
        }
        priceFormatted
        priceWrapped
        items {
            __typename
            ...OrderItemCommonFields
            ...OrderItemPackageFields
            ...OrderItemPackageAddonFields
            ...OrderItemPluginFields
            ...OrderItemDomainInterfaceFields
            ...OrderItemPrivateRegistrationFields
        }
        originalPriceFormatted
        originalPriceWrapped
        type
        nextPaymentDate
        anonymouseDomainsUrl
        fullTitle
        fromDate
        packageExpirationDate
        pluginExpirationDate
        domainExpirationDate
        domainNames
        mainOrderType
        transfer
        paymentSuccessUrl
        paymentFailUrl
        customerCareDomainRestoration
        companyBilling
        voucher {
            code
            text
        }
        identifier
        title
        contactInputs {
            value
            name
            type
            label
            priority
            validation
            ... on SelectContactInput {
                options {
                    text
                    value
                }
                selectedOption {
                    text
                    value
                }
            }
        }
        coveredByVoucher
        targetAddonHash
        targetAddonParentExpirationDate
        targetAddonParentIdentifier
    }
`;

export const paymentMethodFieldsFragment = /* GraphQL */`
    fragment PaymentMethodFields on PaymentServiceType {
        name
        type
        inputs {
            name
            value
            editable
        }
        url
        redirectUrl
        title
        elementId
        oppositeElementId
        icon
        className
        largeIcon
        icons {
            class
            url
        }
        infoboxText
        requiredBillingFields
        newWindow
        iframe
        open
        forceBillingForm
        recurringEnabled
    }
`;
