import api from "../../utils/api";
import * as cookies from '../../utils/cookies';
import Keen from '../../utils/Tracker';
import {FormRequest} from '../../utils/FormRequest';
import queries from './queries';
import { getQueryError, prepareGraphQLData } from '../../utils/graphql';

export {setCurrentPages} from "../Layout/actions";

/**
 * @typedef GeneralRequestData
 * @property {string} id order identifier
 * @property {string} project project name
 * @property {object|undefined} paymentMethod selected payment method object
 */

/**
 * @typedef {import('redux-thunk').ThunkAction} ThunkAction
 * @typedef import('@stripe/stripe-js').StripeCardNumberElement} StripeCardNumberElement
 */

/*
 * action types
 */
export const FETCH_CHECKOUT_START = 'FETCH_CHECKOUT_START';
export const FETCH_CHECKOUT_COMPLETE = 'FETCH_CHECKOUT_COMPLETE';
export const FETCH_CHECKOUT_ERROR = 'FETCH_CHECKOUT_ERROR';
export const CHECKOUT_CHANGE_ITEM_VARIANT_START = 'CHECKOUT_CHANGE_ITEM_VARIANT_START';
export const CHECKOUT_CHANGE_ITEM_VARIANT_COMPLETE = 'CHECKOUT_CHANGE_ITEM_VARIANT_COMPLETE';
export const CHECKOUT_CHANGE_ITEM_VARIANT_ERROR = 'CHECKOUT_CHANGE_ITEM_VARIANT_ERROR';
export const CHECKOUT_TOGGLE_PRIVATE_REGISTRATION_START = 'CHECKOUT_TOGGLE_PRIVATE_REGISTRATION_START';
export const CHECKOUT_TOGGLE_PRIVATE_REGISTRATION_COMPLETE = 'CHECKOUT_TOGGLE_PRIVATE_REGISTRATION_COMPLETE';
export const CHECKOUT_TOGGLE_PRIVATE_REGISTRATION_ERROR = 'CHECKOUT_TOGGLE_PRIVATE_REGISTRATION_ERROR';
export const CHECKOUT_TOGGLE_PACKAGE_ADDITIONAL_ITEM_START = 'CHECKOUT_TOGGLE_PACKAGE_ADDITIONAL_ITEM_START';
export const CHECKOUT_TOGGLE_PACKAGE_ADDITIONAL_ITEM_COMPLETE = 'CHECKOUT_TOGGLE_PACKAGE_ADDITIONAL_ITEM_COMPLETE';
export const CHECKOUT_TOGGLE_PACKAGE_ADDITIONAL_ITEM_ERROR = 'CHECKOUT_TOGGLE_PACKAGE_ADDITIONAL_ITEM_ERROR';
export const CHECKOUT_SUBMIT_VOUCHER_START = 'CHECKOUT_SUBMIT_VOUCHER_START';
export const CHECKOUT_SUBMIT_VOUCHER_COMPLETE = 'CHECKOUT_SUBMIT_VOUCHER_COMPLETE';
export const CHECKOUT_SUBMIT_VOUCHER_ERROR = 'CHECKOUT_SUBMIT_VOUCHER_ERROR';
export const CHECKOUT_DELETE_VOUCHER_START = 'CHECKOUT_DELETE_VOUCHER_START';
export const CHECKOUT_DELETE_VOUCHER_COMPLETE = 'CHECKOUT_DELETE_VOUCHER_COMPLETE';
export const CHECKOUT_DELETE_VOUCHER_ERROR = 'CHECKOUT_DELETE_VOUCHER_ERROR';
export const CHECKOUT_UPDATE_ORDER_COMPLETE = 'CHECKOUT_UPDATE_ORDER_COMPLETE';
export const CHECKOUT_CHANGE_PAYMENTS = 'CHECKOUT_CHANGE_PAYMENTS';
export const CHECKOUT_SET_PAYMENT_ERROR = 'CHECKOUT_SET_PAYMENT_ERROR';
export const CHECKOUT_FINISH_START = 'CHECKOUT_FINISH_START';
export const CHECKOUT_FINISH_COMPLETE = 'CHECKOUT_FINISH_COMPLETE';
export const CHECKOUT_FINISH_ERROR = 'CHECKOUT_FINISH_ERROR';
export const CHECKOUT_BILLING_CHANGE = 'CHECKOUT_BILLING_CHANGE';
export const CHECKOUT_BILLING_TOGGLE = 'CHECKOUT_BILLING_TOGGLE';
export const CHECKOUT_SET_BILLING_ERROR = 'CHECKOUT_SET_BILLING_ERROR';



/*
 * action creators
 */


/**
 * Změni projekt
 * @param {string} project
 * @param {string} orderIdentifier
 * @return {ThunkAction}
 */
export const fetch = (project, orderIdentifier) => (dispatch) =>
{
	dispatch({
		type: FETCH_CHECKOUT_START,
		project,
		orderIdentifier
	});

	queries.getOrderAndPaymentMethods(orderIdentifier, false)
		.then((data) => [
			prepareGraphQLData(data, 'webnodeOrder'),
			prepareGraphQLData(data, 'webnodePaymentMethods')
		]).then(([order, paymentMethods]) => {
			dispatch({
				type: FETCH_CHECKOUT_COMPLETE,
				order: order,
				payments: paymentMethods,
			});
		}).catch((errors) => {
			const error = getQueryError(errors, 'webnodeOrder') ||
				getQueryError(errors, 'webnodePaymentMethods');
			dispatch({
				type: FETCH_CHECKOUT_ERROR,
				error: error ? error.message : errors[0].message,
			});
		});
}

/**
 * @param projectName
 * @param orderId
 * @param errors
 * @return {boolean}
 */
function processIntegrityError(projectName, orderId, errors)
{
	const integrityError = errors.find((error) => error.extensions.errorCode === 'INVALID_INTEGRITY_TOKEN');
	if (integrityError)
	{
		Keen.addKeenEvent('invalid_integrity_token_error', {
			projectName,
			orderId,
		});
		window.location.reload();
		return true;
	}
	return false;
}

/**
 * @param {GeneralRequestData} request
 * @param {object} item
 * @param {object} variant
 * @param {string} integrityToken
 * @returns {ThunkAction}
 */
export const updateServicePeriod = (
	{id, project, paymentMethod},
	item,
	variant,
	integrityToken,
) => (dispatch) =>
{
	dispatch({
		type: CHECKOUT_CHANGE_ITEM_VARIANT_START,
		item: item,
		variant: variant,
	});

	return queries.updateOrderServicePeriod(id, item.hash, variant.interval.period, variant.interval.units, integrityToken)
		.then((data) => prepareGraphQLData(data, 'updateOrderServicePeriod'))
		.then((order) => dispatch(fetchOrderPaymentMethodsAndSetState(project, order, paymentMethod)))
		.then(() => {
			dispatch({
				type: CHECKOUT_CHANGE_ITEM_VARIANT_COMPLETE,
			});
		}).catch((errors) => {
			if (processIntegrityError(project, id, errors))
			{
				return;
			}
			dispatch({
				type: CHECKOUT_CHANGE_ITEM_VARIANT_ERROR,
				error: errors[0].message
			});
		});
}

/**
 * @param {GeneralRequestData} request
 * @param {object} item
 * @param {string} integrityToken
 * @return {ThunkAction}
 */
export const updatePrivateRegistration = (
	{id, project, paymentMethod},
	item,
	integrityToken,
) => (dispatch) =>
{
	const enable = !item.checked;
	if (enable)
	{
		Keen.addKeenEvent('domains_registration_keep_private_enable', {
			ref: new URLSearchParams(window.location.search).get('ref')
		});
	}
	else
	{
		Keen.addKeenEvent('domains_registration_keep_private_disable', {
			ref: new URLSearchParams(window.location.search).get('ref')
		});
	}

	dispatch({
		type: CHECKOUT_TOGGLE_PRIVATE_REGISTRATION_START,
		item: item,
	});

	return queries.updateOrderPrivateRegistration(id, item.domain, enable, item.period, integrityToken)
		.then((data) => prepareGraphQLData(data, 'updateOrderPrivateRegistration'))
		.then((order) => dispatch(fetchOrderPaymentMethodsAndSetState(project, order, paymentMethod)))
		.then(() => {
			dispatch({
				type: CHECKOUT_TOGGLE_PRIVATE_REGISTRATION_COMPLETE,
			});
		}).catch((errors) => {
			if (processIntegrityError(project, id, errors))
			{
				return;
			}
			dispatch({
				type: CHECKOUT_TOGGLE_PRIVATE_REGISTRATION_ERROR,
				error: errors[0].message
			});
		});
}

/**
 * @param {GeneralRequestData} request
 * @param {object} item
 * @param {string} integrityToken
 * @return {ThunkAction}
 */
export const toggleAdditionalItem = (
	{id, project, paymentMethod},
	item,
	integrityToken,
) => (dispatch) =>
{
	dispatch({
		type: CHECKOUT_TOGGLE_PACKAGE_ADDITIONAL_ITEM_START,
		item: item,
	});

	let promise;
	if (!item.checked)
	{
		promise = queries.addOrderAdditionalItem(id, item.identifier, item.period, integrityToken)
			.then((data) => prepareGraphQLData(data, 'addOrderAdditionalItem'));
	}
	else
	{
		promise = queries.deleteOrderAdditionalItem(id, item.hash, integrityToken)
			.then((data) => prepareGraphQLData(data, 'deleteOrderAdditionalItem'));
	}

	return promise.then((order) => dispatch(fetchOrderPaymentMethodsAndSetState(project, order, paymentMethod)))
		.then(() => {
			dispatch({
				type: CHECKOUT_TOGGLE_PACKAGE_ADDITIONAL_ITEM_COMPLETE,
			});
		})
		.catch((errors) => {
			if (processIntegrityError(project, id, errors))
			{
				return;
			}
			dispatch({
				type: CHECKOUT_TOGGLE_PACKAGE_ADDITIONAL_ITEM_ERROR,
				error: errors[0].message
			});
		});
}

/**
 *
 * @param {string} project
 * @param {object} order
 * @param {object} currentPaymentMethod
 * @returns {ThunkAction}
 */
const fetchOrderPaymentMethodsAndSetState = (
	project,
	order,
	currentPaymentMethod
) => (dispatch) =>
{
	return queries.getPaymentMethods(
		order.identifier,
		false,
		null,
		null,
		null,
		null,
	)
		.then((data) => prepareGraphQLData(data, 'webnodePaymentMethods'))
		.then((payments) => {
			payments = currentPaymentMethod ?
				payments.map(method => {
					return {
						...method,
						open: method.elementId === currentPaymentMethod.elementId ||
							method.oppositeElementId === currentPaymentMethod.elementId,
					}
				}) :
				payments;

			dispatch({
				type: CHECKOUT_UPDATE_ORDER_COMPLETE,
				order: order,
				payments: payments,
			});

			return {order, payments};
		})
		.catch((error) => {
			throw error;
		});
};

/**
 * @param {GeneralRequestData} general
 * @param {string} code
 * @param {string} integrityToken
 * @returns {ThunkAction}
 */
export const updateVoucher = (
	{id, project, paymentMethod},
	code,
	integrityToken,
) => (dispatch) =>
{
	dispatch({
		type: code ? CHECKOUT_SUBMIT_VOUCHER_START : CHECKOUT_DELETE_VOUCHER_START,
		code,
	});

	return queries.updateOrderVoucher(id, code, integrityToken)
		.then((data) => prepareGraphQLData(data, 'updateOrderVoucher'))
		.then((order) => dispatch(fetchOrderPaymentMethodsAndSetState(project, order, paymentMethod)))
		.then(({ order }) => {
			dispatch({
				type: code ? CHECKOUT_SUBMIT_VOUCHER_COMPLETE : CHECKOUT_DELETE_VOUCHER_COMPLETE,
				order: order,
			});
		}).catch((errors) => {
			if (processIntegrityError(project, id, errors))
			{
				return;
			}
			dispatch({
				type: code ? CHECKOUT_SUBMIT_VOUCHER_ERROR : CHECKOUT_DELETE_VOUCHER_ERROR,
				error: errors[0].message,
			});
		});
}

/**
 * Změní aktuální platební metodu
 * @param {string} method
 * @return {{method: *, type: string}}
 */
export function changePaymentMethod(method)
{
	return {
		type: CHECKOUT_CHANGE_PAYMENTS,
		method
	}
}

/**
 * Nastaví error u aktuální platební metody
 * @return {{method: *, type: string}}
 */
export function setPaymentError(key, value)
{
	return {type: CHECKOUT_SET_PAYMENT_ERROR, key, value}
}

/**
 * Zkontroluje jestli není zobrazen hláška o chybějících udajích k doméně a pokud ano tak ji zvýrazní
 *
 * @return {boolean}
 */
function checkMissingPersonalData()
{
	// todo refaktor
	// Měla by spíš řešit komponenta na základě nějakýho error state
	const missingPersonalData = document.getElementById('missing-personal-data');
	if (missingPersonalData)
	{
		missingPersonalData.closest('.checkout-basket-item').scrollIntoView();
		missingPersonalData.querySelector('span.alert-triangle.info').classList.add('hide');
		missingPersonalData.querySelector('span.alert-triangle.warning').classList.remove('hide');
		missingPersonalData.querySelector('span.alert-triangle.warning').classList.add('refresh');

		window.setTimeout(function () {
			missingPersonalData.querySelector('span.alert-triangle.warning').classList.remove('refresh');
		}, 500);

		return false;
	}

	return true;
}

export const changeBillingValue = (name, value) => ({type: CHECKOUT_BILLING_CHANGE, name, value})
export const setBillingError = (name, value) => ({type: CHECKOUT_SET_BILLING_ERROR, name, value})
export const toggleBilling = () => ({type: CHECKOUT_BILLING_TOGGLE})

/**
 * @param {GeneralRequestData} requestData
 * @param {bool} coveredByVoucher
 * @param {object|null} contactData
 * @param {{
 *     cardNumberElement: StripeCardNumberElement|null,
 *     stripe: Stripe|null
 * }} stripe
 * @param {string} measuringId
 * @param {(order: object) => void} onComplete
 * @param {string} integrityToken
 * @return {ThunkAction<Promise<any>>}
 */
export const finishOrder = (
	{id, project, paymentMethod},
	coveredByVoucher,
	contactData,
	stripe,
	measuringId,
	onComplete,
	integrityToken,
) => async (dispatch) => {
	// nejprve zkontrolujeme jestli není potřeba doplnit udaje k doméně!
	if (!checkMissingPersonalData()) {
		return;
	}

	dispatch({
		type: CHECKOUT_FINISH_START,
	});

	// complete order
	let order;
	try
	{
		let contact = null;
		if (contactData)
		{
			contact = {
				contactFields: Object.entries(contactData)
					.filter(([, val]) => val !== '')
					.map(([name, value]) => ({
						name,
						value
					}))
			};
		}

		const serviceName = paymentMethod ? paymentMethod.name : null;
		order = await queries.finishOrder(id, contact, serviceName, integrityToken)
			.then((data) => prepareGraphQLData(data, 'finishOrder'));

		const timeStart = cookies.get(measuringId + '-keen_checkout_submitted');
		const timeNowInSeconds = Math.floor(Date.now() / 1000);
		Keen.addKeenEvent('checkout_submit_success', {
			'projectName': project,
			'orderId': id,
			'data' : {
				'durationInSeconds': timeNowInSeconds - timeStart
			},
		});

		// pokud byla objednavka pokryta voucherem, tak uz jen dokoncime
		if (coveredByVoucher)
		{
			window.location = order.paymentSuccessUrl + '&measuringId=' + measuringId;
			return;
		}
	}
	catch (errors)
	{
		if (processIntegrityError(project, id, errors))
		{
			return;
		}

		const error = getQueryError(errors, 'finishOrder');
		dispatch({
			type: CHECKOUT_FINISH_ERROR,
			billingFormValidationErrors: error?.extensions?.errors | {},
			error: error?.extensions?.errors ? null : errors[0].message,
		});
		return;
	}

	// pokud je vse ok, potrebujeme jeste poslat request na MS-payments
	const paymentMethodInputs = paymentMethod.inputs.reduce((acc, input) => {
			acc[input.name] = input.value;
			return acc;
		},
		{}
	);

	// pošleme request na dokončení objedávky i na MS payments
	let payments;
	try
	{
		const url = new URL(window.location.href);
		payments = await queries.getPaymentMethods(
			id,
			true,
			paymentMethodInputs.MerchantTransactionID ?? null,
			paymentMethodInputs.MethodID ?? null,
			url.searchParams.get('ref') ?? null,
			url.searchParams.get('token') ?? null,
		).then((data) => prepareGraphQLData(data, 'webnodePaymentMethods'));

		// Pokud se vše povedlo, překreslíme ještě objedávku a platební metody (vč platebního formuláře)
		// u stripu to trvá dlouho a může to pak ještě failnout a zobrazit error
		if (paymentMethod.type !== 'stripe')
		{
			dispatch({
				type: CHECKOUT_FINISH_COMPLETE,
				order: order,
				payments: payments,
			});
		}
	}
	catch (errors)
	{
		const err = getQueryError(errors, 'webnodePaymentMethods');
		dispatch({
			type: CHECKOUT_FINISH_ERROR,
			billingFormValidationErrors: {},
			error: err?.message || errors[0].message,
		});
		return;
	}

	try
	{
		if (paymentMethod.type === 'stripe')
		{
			let secureResponse;
			try
			{
				secureResponse = await api.payments.secure(project, id, paymentMethodInputs)
			}
			catch (e)
			{
				// tak to je v puvodní verzi, osobně bych to ale nechal klidně padnout
				dispatch(setPaymentError('card', e.message))
				dispatch({type: CHECKOUT_FINISH_ERROR, billingFormValidationErrors: {}});
				return;
			}
			const paymentMethod = {
				card: stripe.cardNumberElement
			};
			if (
				secureResponse.billingDetails &&
				!Array.isArray(secureResponse.billingDetails) &&
				Object.keys(secureResponse.billingDetails).length > 0
			)
			{
				paymentMethod.billing_details = secureResponse.billingDetails;
			}
			const {error} = await stripe.stripe.confirmCardPayment(
				secureResponse.clientSecret,
				{
					payment_method: paymentMethod
				}
			);

			if (!!error)
			{
				window.location = order.paymentFailUrl + '&measuringId=' + measuringId;
				return;
			}
			dispatch({
				type: CHECKOUT_FINISH_COMPLETE,
				order,
				payments
			});
			onComplete(order);
			window.location = order.paymentSuccessUrl + '&measuringId=' + measuringId;
		}
		else
		{
			onComplete(order);

			// data se vytáhnou z právě updatevoané platební metody (v případě že ms-payments něco změní)
			const {url, inputs} = payments.find(p => p.elementId === paymentMethod.elementId) ?? paymentMethod;
			const data = inputs?.reduce((acc, {name, value}) => (acc[name] = value, acc),{});
			(new FormRequest(url, data)).send();
		}
	}
	catch (err)
	{
		window.location = order.paymentFailUrl + '&measuringId=' + measuringId;
	}
}
