import React from 'react';
import ActionLink from './ActionLink';

export default function ActionSpanLink ({children, ...props})
{
	return (
		<ActionLink {...props}>
			<span>{children}</span>
		</ActionLink>
	);
}
ActionSpanLink.propTypes = ActionLink.propTypes;